.header {
    display: none;
}

.logo-container--mobile {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 5px;
    margin-left: 0.5rem;
    width: 50%;
}

.logo-container--mobile .logo-img {
    width: 45px;
}

.logo-container--mobile .logo-text {
    color: rgb(33, 33, 33);
    text-decoration: none;
    margin-left: 10px;
    text-align: center;
}

.main-navigation--mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-navigation__menu-btn {
    width: 3rem;
    height: 2rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0.75rem;
    margin-bottom: 0.3rem;
    margin-right: 1.5rem;
    cursor: pointer;
}

.main-navigation__menu-btn span {
    display: block;
    width: 2.5rem;
    height: 3px;
    background: black;
    margin-top: 0;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.main-navigation__menu-btn span:nth-of-type(1) {
    width: 2.5rem;
}

.main-navigation__menu-btn span:nth-of-type(2) {
    width: 2rem;
}

@media (min-width: 768px) {
    .main-navigation--mobile {
        display: none;
    }

    .main-navigation__menu-btn {
        display: none;
    }

    .header {
        width: 100vw;
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-primary);
        box-shadow: 0px 2px 4px var(--color-primary-darkest);
    }

    .logo-container {
        font-size: 0.8rem;
        padding: 5%;
        display: flex;
        align-items: center;
        margin-top: 7px;
    }

    .logo-container .logo-img {
        width: 60px;
    }

    .logo-container .logo-text {
        color: white;
        text-decoration: none;
        margin-left: 15px;
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .logo-container {
        font-size: 1.9rem;
    }
}
