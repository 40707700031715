*,
*::before,
*::after {
    box-sizing: border-box;
}

button,
input,
textarea,
select {
    font: inherit;
}

:root {
    --color-primary: hsl(210, 40%, 60%);
    --color-primary-dark: hsl(210, 35%, 40%);
    --color-primary-darker: hsl(210, 35%, 30%);
    --color-primary-darkest: hsl(210, 35%, 20%);

    --background-color-light: hsl(0, 0%, 94%);
    --background-color-dark: hsl(0, 0%, 91%);
    --background-color-darker: hsl(0, 0%, 85%);

    --font-color: hsl(0, 0%, 5%);
}

html,
body {
    width: 100%;
}

body {
    font-size: 1.2rem;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Times New Roman', Times, serif;
    color: var(--font-color);
    margin: 0;
    background-color: var(--background-color-light);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.centred {
    text-align: center;
}

.section-content {
    width: 100%;
}

.section-content.dark-bg {
    background-color: var(--background-color-dark);
}

.divider {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid var(--color-primary);
    margin-bottom: 50px;
}

@media (min-width: 768px) {
    .divider {
        width: 500px;
    }
}
