.modal {
    z-index: 100;
    position: fixed;
    top: 10vh;
    left: 5%;
    width: 90%;
    max-height: 80%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    overflow: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal::-webkit-scrollbar {
    display: none;
}

.modal__header {
    width: 100%;
    padding: 1rem 0;
    background: #2a006e;
    color: white;
}

.modal__header h2 {
    margin: 1rem;
}

.modal__content {
    padding: 1rem 0.5rem;
}

.modal__footer {
    padding: 0.5rem 0.5rem;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
}

/* @media (min-width: 768px) {
    .modal {
        left: calc(50% - 30rem);
        width: 60rem;
    }
} */

@media (min-width: 768px) {
    .modal {
        z-index: 100;
        position: fixed;
        top: 10vh;
        left: 10%;
        width: 80%;
        max-height: 80%;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 8px;
        overflow: scroll;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .modal::-webkit-scrollbar {
        display: none;
    }

    .modal__content {
        padding: 1rem 0.5rem;
    }

    .modal__footer {
        padding: 1rem 0.5rem;
        position: sticky;
        bottom: 0;
    }
}

@media (min-width: 1200px) {
    .modal {
        top: 15vh;
        left: 20%;
        width: 60%;
    }
}

.modal-enter {
    transform: translateY(-10rem);
    opacity: 0;
}

.modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
}

.modal-exit {
    transform: translateY(0);
    opacity: 1;
}

.modal-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
}
