.file-upload-checkboxes {
    display: flex;
    flex-direction: column;
}

.file-upload-checkboxes label {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.3rem;
    text-indent: -1.3rem;
}
