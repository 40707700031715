.nav-links {
    margin: 0;
    padding: 0;
    padding-right: 5%;
}

.nav-links ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin-left: 1.5rem;
    margin-top: 1rem;
    font-size: 1.25rem;
}

.nav-links a {
    text-decoration: none;
    color: #313131;
}

.nav-links a:hover,
.nav-links a:active {
    color: #313131;
    text-decoration: underline;
}

.logout-btn {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.logout-btn:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .logout-btn {
        text-decoration: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }

    .logout-btn:hover {
        color: rgb(37, 37, 37);
        text-decoration: none;
    }

    .nav-links ul {
        flex-direction: row;
    }

    .nav-links li {
        font-size: 1rem;
    }

    .nav-links li {
        margin: 0 0.5rem;
    }

    .nav-links a {
        color: rgb(255, 255, 255);
        text-decoration: none;
    }

    .nav-links a:hover,
    .nav-links a:active,
    .nav-links a.active {
        color: rgb(37, 37, 37);
        text-decoration: none;
    }

    .nav-links button {
        border: 1px solid white;
        color: white;
        background: transparent;
    }

    .nav-links button:hover,
    .nav-links button:active {
        background: #f8df00;
        color: #292929;
    }
}

@media (min-width: 1000px) {
    .nav-links li {
        font-size: 1.4rem;
    }
}
