.committee-header {
    text-align: center;
    font-size: 1rem;
}

.about-us-container {
    display: block;
    font-size: 1.1rem;
    width: 90%;
    margin: 0 auto;
}

.about-us-header {
    text-align: center;
}

.team-img-container {
    text-align: center;
}

.team-img {
    width: 70%;
    border-bottom: 1px solid rgb(141, 141, 141);
    margin-bottom: 0.3rem;
    z-index: -1;
}

.team-img-bio p.bio-see-more {
    text-align: center;
    cursor: pointer;
    color: rgb(85, 85, 255);
}

.team-img-bio p a {
    cursor: pointer;
    color: rgb(85, 85, 255);
}

.committee-dismiss-modal {
    font-weight: bold;
    color: blue;
    font-size: 1.5rem;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
}

.divider--committee {
    display: block;
    width: 85%;
    margin: 0 auto;
    border-bottom: 1px solid var(--color-primary);
    margin-bottom: 35px;
}

@media (min-width: 768px) {
    .about-us-container {
        font-size: 1.1rem;
        width: 80%;
    }

    .team-img {
        width: 60%;
    }
}

@media (min-width: 1000px) {
    .divider--committee {
        display: none;
    }

    .about-us-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8rem;
        margin-bottom: 3rem;
        width: 100%;
    }

    .about-us-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .team-gallery {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2);
        column-gap: 0px;
        row-gap: 10px;
        justify-content: center;
        align-items: flex-start;
        justify-items: center;
    }

    .team-img-container {
        margin: 1.1rem;
        border: 1px solid rgb(141, 141, 141);
        text-align: center;
        border-radius: 1%;
    }

    .team-img {
        width: 100%;
        border-bottom: 1px solid rgb(141, 141, 141);
        margin-bottom: 0.3rem;
        position: relative;
        z-index: -1;
    }

    .team-img-bio {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 200px;
    }

    .team-img-bio p:not(.bio-see-more) {
        display: block;
        text-align: left;
        margin: 0.4rem;
    }

    .team-img-container.centered {
        text-align: center;
        grid-row: 2;
        grid-column: 2 / span 2;
        align-self: center;

        /* this seems to be a sweet spot for maintaining the right size compared to the other images */
        width: 44%;
    }
}

@media (min-width: 1200px) {
    .about-us-container {
        font-size: 0.95rem;
    }

    .about-us-body {
        width: 88%;
    }

    .team-img-container.centered {
        width: 44%;
    }
}

@media (min-width: 1700px) {
    .about-us-container {
        font-size: 1.1rem;
    }

    .about-us-body {
        width: 80%;
    }
}
