.news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
}

.news-header {
    text-align: center;
}

.news-body {
    width: 60%;
    text-align: justify;
    font-size: 1.3rem;
}

.news-footer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}

.footer-logo {
    width: 200px;
    margin: 20px;
}

.news-title {
    text-align: center;
}
