.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-text {
    font-size: 1rem;
}

.homepage-header {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
    font-size: 2rem;
    font-weight: bold;
}

.homepage-body {
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.homepage-footer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
}

.homepage-title {
    text-align: center;
}

.homepage-section-header {
    text-align: center;
}

.homepage-section-container {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    /* padding-top: 50px; */
    padding-bottom: 50px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
}

.publications-list-item {
    margin: 0;
}

.publications-list-item.publication-title {
    display: block;
}

.publications-list-item.publication-type {
    font-weight: bold;
}

.publications-list-item.publication-link {
    font-size: 1rem;
    margin-bottom: 3rem;
}

.divider.homepage-section-two {
    width: 300px;
}

.hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(16, 17, 91, 0.6)),
        url('../assets/homepage-images/surgery-hero.jpg');

    /* Set a specific height */
    height: 50vh;
    width: 100vw;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
    filter: grayscale(50%);
}

/* Place text in the middle of the image */
.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-30%, -50%);
    color: white;
    border: 1px solid white;
    padding: 30px 40px 30px 40px;
    background-color: rgba(173, 173, 173, 0.5);
    font-size: 0.8rem;
}

@media (min-width: 768px) {
    /* Place text in the middle of the image */
    .hero-text {
        padding: 60px 80px 60px 80px;
        font-size: 0.9rem;
    }
}

@media (min-width: 1000px) {
    .divider.homepage-section-two {
        width: 500px;
    }

    .homepage-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homepage-header {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 25px;
        font-size: 2rem;
        font-weight: bold;
    }

    .homepage-body {
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .homepage-footer {
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: 100%;
    }

    .footer-logo {
        width: 200px;
        margin: 20px;
    }

    .homepage-title {
        text-align: center;
    }

    .homepage-section-container {
        width: 62%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0 auto;
        /* padding-top: 50px; */
        padding-bottom: 50px;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .homepage-section-text {
        margin-left: 50px;
        margin-right: 50px;
        text-align: justify;
        width: 500px;
    }

    .homepage-section-container.section-one {
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 600px;
    }

    .hero-image {
        /* Set a specific height */
        height: 100vh;
        width: 100vw;
    }

    /* Place text in the middle of the image */
    .hero-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 60px 120px 60px 120px;
        font-size: 1.2rem;
    }

    .homepage-section-container.publications-list-container {
        justify-content: center;
        text-align: center;
    }

    .homepage-section-container.publications-list-container .homepage-section-text {
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        line-height: normal;
    }

    .publications-list {
        display: flex;
    }

    .publications-list-item {
        margin: 20px 20px 0 20px;
    }

    .publications-list-item.publication-link {
        font-size: 1rem;
        margin-bottom: 0;
    }

    .publications-list-item-container {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}
