.footer {
    background-color: var(--background-color-darker);
    color: #616161;
    font-size: 1rem;
    padding: 20px 0;
    box-shadow: 0px 0px 5px var(--color-primary-darkest);
    margin-top: auto; /* to make the footer stick to the bottom of the screen */
}

.footer a {
    text-decoration: none;
    color: #616161;
}

.footer ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

@media (min-width: 768px) {
    .footer-inner {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 20px 0 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .footer div i {
        margin-right: 10px;
    }

    .footer li {
        margin-right: 30px;
        margin-bottom: 20px;
        list-style: none;
    }
}
