.media-form {
    position: relative;
    list-style: none;
    margin: 1rem auto;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    background: white;
}
