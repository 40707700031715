.file-upload.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file-upload__preview {
    width: 13rem;
    height: 13rem;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
}

.file-upload__preview img {
    width: 100%;
    height: 100%;
    max-width: 13rem;
    max-height: 13rem;
    object-fit: contain;
}

.file-upload__preview video {
    width: 100%;
    max-width: 13rem;
    max-height: 13rem;
    object-fit: contain;
}
