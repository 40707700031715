.layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column; /* works with the footer to make the footer stick to the bottom of the screen */
}

/* main {
    padding-bottom: 50px;
} */
