.side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: rgb(246, 246, 246, 0.9);
}

.side-drawer nav {
    margin-top: 1rem;
}

.slide-enter {
    transform: translateX(-10rem);
    opacity: 0;
}

.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
}

.slide-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-exit-active {
    transform: translateX(-10rem);
    opacity: 0;
    transition: all 200ms;
}
