.committee-bio-content {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.team-img-modal {
    display: none;
}

@media (min-width: 768px) {
    .team-img-modal {
        display: block;
        width: 250px;
        margin-right: 2rem;
    }
}

@media (min-width: 1700px) {
    .committee-bio-content {
        font-size: 1.15rem;
    }
}

@media (min-width: 1900px) {
    .committee-bio-content {
        font-size: 1.2rem;
    }
}
